import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const Richiamo = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full",
        objectFill: "cover"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/Richiamo/1.jpg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/Richiamo/2.jpg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/Richiamo/3.jpg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "",
        "",
        "",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Richiamo Coffee" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-24 mr-5">
                            <StaticImage src="../../images/brand/brand-3.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Richiamo Coffee</h2>
                        <p className="font-light text-gray-600">10:00am – 10.00pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 019-509 1777</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">       
                        Richiamo Coffee, a renowned chain of premium coffee shops, invites customers to indulge in an exceptional café experience. With an unwavering dedication to provide top-notch products and outstanding service, Richiamo Coffee has earned a global reputation in the industry. Their extensive selection of coffee, expertly crafted by connoisseurs using the finest beans, guarantees a gratifying experience for even the most discerning coffee aficionados. The array of high-quality offerings includes irresistible pastries and cakes, prepared with fresh ingredients to ensure excellence in taste and quality. Richiamo Coffee warmly welcomes customers into a cozy ambiance, where friendly staff provide service that evokes the comfort of home. Embark on a remarkable coffee journey with Richiamo Coffee, where every sip and bite leaves a memorable imprint.
                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-4-richiamo.jpeg`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default Richiamo
